import React, { useEffect } from "react";

import { Box, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

//for example: { key: "enable-manual-orders", value: "featureManualOrdersEnabled" }
const ALLOWED_FEATURES: { key: string; value: string }[] = [{ key: "enable-exit-strategy", value: "featureExitStrategyEnabled" }, { key: "enable-credits-subscription", value: "featureCreditsSubscriptionEnabled" }];

const Feature: React.FC = () => {
  const navigate = useNavigate();
  const { type } = useParams<{ type: string }>();

  useEffect(() => {
    const find = ALLOWED_FEATURES.find((item) => item.key === type);

    if (find) {
      localStorage.setItem(find.value, "true");
    }

    navigate("/dashboard");
  }, [type, history]);

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <CircularProgress color="primary" />
    </Box>
  );
};

export default Feature;
