import { useEffect, useState } from "react";

import { dcaBotsCreateExitStrategy, dcaBotsPreviewExitStrategy } from "API/calls";
import Big from "big.js";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BuyBackModeType, ExitStrategyType, SellOrderStatus } from "types/enums";
import { IDetails, IPreviewExitStrategy } from "types/types";
import { increaseByPercentage } from "utils";
import { getBigWithComma, getNumberWithComma, renderNumber } from "utils/formatter";
import * as Yup from "yup";

interface IProps {
  details: IDetails;
  baseCurrency: string;
  counterCurrency: string;
  currencyPairPriceScale: number;
  id: string;
  type: ExitStrategyType | null;
}

export interface ExitStrategyFormikValues {
  totalAmount: string | number;
  firstSellingPrice: string | number;
  highestSellingPricePct: number;
  sellingPriceStepPct: number;
  pctOfGrowthToSell: number;
  buyBackMode: BuyBackModeType;
  buyBackPriceStepPct: number;
  buyBackValuePct: number;
}

const useExitStrategyData = ({ details, baseCurrency, counterCurrency, currencyPairPriceScale, id, type }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [previewData, setPreviewData] = useState<IPreviewExitStrategy>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik<ExitStrategyFormikValues>({
    initialValues: {
      totalAmount: getNumberWithComma(details.totalAmount),
      firstSellingPrice: getBigWithComma(
        new Big(increaseByPercentage(details.bidPrice ?? 0, 3)).round(currencyPairPriceScale, Big.roundDown)
      ),
      highestSellingPricePct: 50,
      sellingPriceStepPct: 3,
      pctOfGrowthToSell: 100,
      buyBackMode: BuyBackModeType.NONE,
      buyBackPriceStepPct: 10,
      buyBackValuePct: 100,
    },
    validationSchema: Yup.object({
      totalAmount: Yup.string()
        .transform((value) => value.replace(",", "."))
        .test("min", t("minTotalAmount", { value: 0, currency: baseCurrency }), (value) => Number(value) > 0)
        .test(
          "max",
          t("maxTotalAmount", { value: getNumberWithComma(details.totalAmount), currency: baseCurrency }),
          (value) => Number(value) <= details.totalAmount
        )
        .required(t("fieldRequired")),
      firstSellingPrice: Yup.string()
        .transform((value) => value.replace(",", "."))
        .test(
          "min",
          t("minPrice", { value: renderNumber(details.bidPrice), currency: counterCurrency }),
          (value) => Number(value) > details.bidPrice
        )
        .test(
          "max",
          t("maxPrice", {
            value: renderNumber(increaseByPercentage(details.bidPrice, 100)),
            currency: counterCurrency,
          }),
          (value) => Number(value) <= increaseByPercentage(details.bidPrice, 100)
        )
        .required(t("fieldRequired")),
      highestSellingPricePct: Yup.number()
        .min(9, t("minPrice", { value: 9 }))
        .max(1000, t("maxPrice", { value: 1000 }))
        .required(t("fieldRequired")),
      sellingPriceStepPct: Yup.number()
        .min(0, t("minPrice", { value: 0 }))
        .max(10, t("maxPrice", { value: 10 }))
        .required(t("fieldRequired")),
      pctOfGrowthToSell: Yup.number()
        .min(0, t("minTotalAmount", { value: 0 }))
        .max(1000, t("maxTotalAmount", { value: 1000 }))
        .required(t("fieldRequired")),
    }),
    onSubmit: (values) => {
      handleCreate(values);
    },
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      if (formik.values.totalAmount && formik.values.firstSellingPrice && type) {
        (async () => {
          try {
            const response = await dcaBotsPreviewExitStrategy(id, {
              params: {
                type: ExitStrategyType.PCT_OF_GROWTH_SELL,
                ...formik.values,
                totalAmount: `${formik.values.totalAmount}`.replace(",", "."),
                firstSellingPrice: `${formik.values.firstSellingPrice}`.replace(",", "."),
              },
            });
            setPreviewData(response.data);
          } catch (e: any) {}
        })();
      }
    }, 500);

    return () => clearTimeout(handler);
  }, [JSON.stringify(formik.values), type]);

  const handleCreate = async (values: ExitStrategyFormikValues) => {
    try {
      setIsProcessing(true);
      const { buyBackMode, buyBackPriceStepPct, buyBackValuePct } = values;

      const sellOrders = previewData?.sellOrders.map((order) => ({
        ...order,
        status: order.status === SellOrderStatus.ENOUGH_BALANCE ? SellOrderStatus.APPROVED : order.status,
      }));

      await dcaBotsCreateExitStrategy(id, {
        params: {
          type: ExitStrategyType.PCT_OF_GROWTH_SELL,
          ...values,
          totalAmount: `${values.totalAmount}`.replace(",", "."),
          firstSellingPrice: `${values.firstSellingPrice}`.replace(",", "."),
          buyBackMode,
          buyBackPriceStepPct: buyBackMode !== BuyBackModeType.NONE ? buyBackPriceStepPct : undefined,
          buyBackValuePct: buyBackMode !== BuyBackModeType.NONE ? buyBackValuePct : undefined,
        },
        sellOrders,
      });
      navigate(`/strategie/${id}`);
      enqueueSnackbar(t("exitStrategy.create.summary.createSuccess"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("exitStrategy.create.summary.createError"), { variant: "error" });
      setIsProcessing(false);
    }
  };

  return {
    formik,
    previewData,
    isProcessing,
  };
};

export default useExitStrategyData;
