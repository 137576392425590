import { Box, InputAdornment } from "@mui/material";
import Big from "big.js";
import PriceField from "components/elements/PriceField";
import Slider from "components/elements/Slider";
import { getBigWithComma } from "utils/formatter";

interface IProps {
  id: string;
  precision?: number;
  label: string;
  formik: any;
  currency: string;
  max: number;
  min?: number;
  sliderColor?: string;
}

const InputWithSlider = ({ id, precision, label, formik, currency, max, min = 0, sliderColor = "success.main" }: IProps) => {

  const getSliderStepSize = (value: number) => {
    const valueFraction = value / 100;
    if (valueFraction > 1) {
      return 1;
    }
    return valueFraction;
  };

  const getSliderInputValue = (value: number | string): number => {
    if (typeof value === "number") {
      return value;
    } else {
      return Number(value.replace(",", "."));
    }
  };

  const onSliderChange = (_: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      formik.setFieldValue(id, getBigWithComma(new Big(newValue).round(precision, Big.roundDown)));
    }
  };

  const getSliderLabelFormat = (value: number) => {
    return `${getBigWithComma(new Big(value).round(precision, Big.roundDown))} ${currency}`;
  }

  return (
    <>
      <PriceField
        autoComplete="off"
        allowZero
        precision={precision}
        fullWidth={true}
        id={id}
        name={id}
        label={label}
        value={formik.values[id] ?? 0}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors[id])}
        helperText={formik.errors[id]}
        InputProps={{
          endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
        }}
      />
      <Box sx={{ px: 1, pt: 1 }}>
        <Slider
          key={`slider-${id}-${formik.values[id]}`}
          defaultValue={getSliderInputValue(formik.values[id])}
          min={min}
          step={getSliderStepSize(max)}
          max={max}
          onChangeCommitted={onSliderChange}
          valueLabelDisplay="auto"
          valueLabelFormat={getSliderLabelFormat}
          sx={{ color: sliderColor }}
        />
      </Box>
    </>
  );
};

export default InputWithSlider;
