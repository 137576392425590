import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Checklist, Dollar, StatisticsMonitor, User3 } from "components/icons";
import HomeBox from "components/modules/HomeBox";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans, useTranslation } from "react-i18next";

const ITEMS = [
  {
    icon: <User3 style={{ width: "32px", height: "32px", color: "black" }} />,
    title: "homepage.weHelp.registered",
    value: "12 700+",
  },
  {
    icon: <StatisticsMonitor style={{ width: "32px", height: "32px", color: "black" }} />,
    title: "homepage.weHelp.bots",
    value: "7 300+",
  },
  {
    icon: <Checklist style={{ width: "32px", height: "32px", color: "black" }} />,
    title: "homepage.weHelp.orders",
    value: "800 000+",
  },
  {
    icon: <Dollar style={{ width: "32px", height: "32px", color: "black" }} />,
    title: "homepage.weHelp.volume",
    value: "2+ miliardy Kč",
  },
];

const Statistics = () => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <HomeBox title={t("homepage.weHelp.title")} size={isMobile ? undefined : "small"}>
      <Grid container spacing={3} pt={{ xs: 4, md: 6 }}>
        {ITEMS.map((item, index) => (
          <Grid item key={index} xs={6} lg={3}>
            {item.icon}
            <Typography
              sx={{ pt: 2 }}
              fontWeight={600}
              fontSize={{ xs: "1rem", md: "1.25rem" }}
              color={colors["blueBlack"]}>
              {item.value}
            </Typography>
            <Typography fontWeight={300} color={colors["blueGray"]} fontSize={{ xs: "1rem", md: "1.25rem" }}>
              <Trans i18nKey={item.title} />
            </Typography>
          </Grid>
        ))}
      </Grid>
    </HomeBox>
  );
};

export default Statistics;
