import React, { useState } from "react";

import { Box, Button, Stack, Theme, useMediaQuery, useTheme } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MUITableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { dcaBotsDeleteManualOrder } from "API/calls";
import BackdropLoading from "components/elements/BackdropLoading";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import UsdWrapper, { TypographyItem } from "components/elements/UsdWrapper";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import useConfirm from "hooks/useConfirm";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { OrderTriggering, Side } from "types/enums";
import { IRunsListOrder } from "types/types";
import { getColorByMode, processErrorCode } from "utils";
import { renderNumber } from "utils/formatter";

import ManualOrderModal, { IManualOrder } from "../../Balance/ManualOrderModal";
import IProps, { ITableCell, IPriceInfoValue } from "./types";

export const orderStatusColors = (theme: Theme) => {
  const { colors } = useMUIThemeModeContext();

  return {
    PENDING_NEW: theme.palette.tertiary.main,
    OPEN: theme.palette.tertiary.main,
    FILLED: colors.success,
    PARTIALLY_FILLED: colors.success,
    PARTIALLY_CANCELED: colors.success,
    ERROR_CALCULATING: colors.error,
    ERROR_SMALL_BALANCE: colors.error,
    ERROR_PLACING: colors.error,
    ERROR_CANCELING: colors.error,
    PENDING_CANCEL: getColorByMode(theme, "black", colors.gray700),
    CANCELED: getColorByMode(theme, "black", colors.gray700),
  };
};

export const TableCell: React.FC<ITableCell> = ({ children, withBorder, ...props }) => {
  return (
    <MUITableCell
      {...props}
      sx={{
        padding: { xs: "6px 6px", sm: "6px 16px" },
        border: withBorder ? undefined : "none",
      }}>
      {children}
    </MUITableCell>
  );
};

const PriceInfoValue = ({ value, scale, currency, baseCurrency, side }: IPriceInfoValue) => {
  const { t } = useTranslation();

  return (
    <Typography variant="caption" color={"secondary"}>
      {t(`dcaBots.runsList.priceInfo${side === Side.BUY ? "Buy" : "Sell"}`, {
        baseCurrency,
        counterCurrency: currency,
        price: value ? renderNumber(value, scale, true) : "-",
      })}
    </Typography>
  );
};

const OrdersTable = ({
  orders,
  baseCurrency,
  counterCurrency,
  exchangeEnum,
  price,
  refreshDetail,
  orderId,
  note,
  isRunManual,
}: IProps) => {
  const theme = useTheme();
  const { id } = useParams();
  const { colors } = useMUIThemeModeContext();
  const { confirm } = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [manualOrder, setManualOrder] = useState<IManualOrder>();
  const availableorderStatusColors = orderStatusColors(theme);
  const { t } = useTranslation();
  const { currencyPairBaseScale, currencyPairPriceScale, counterCurrScale } = useExchangesMetadata(
    exchangeEnum ?? "",
    `${baseCurrency}/${counterCurrency}`
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const priceInfo = () => {
    const limitOrder = orders.find((order) => order.type === "LIMIT" && order.triggering !== "INSTANT");

    if (limitOrder && !isRunManual) {
      return (
        <TableRow>
          <TableCell colSpan={4}>
            <UsdWrapper value={price} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
              <PriceInfoValue
                value={price}
                scale={currencyPairPriceScale}
                currency={counterCurrency}
                baseCurrency={baseCurrency}
                side={limitOrder.side}
              />
            </UsdWrapper>
          </TableCell>
        </TableRow>
      );
    }

    return null;
  };

  const handleDeleteManualOrder = async () => {
    if (!id) return;

    try {
      await confirm({
        title: t("dcaBots.runsList.manualOrderDelete.title"),
        description: t("dcaBots.runsList.manualOrderDelete.description"),
        dialogProps: { maxWidth: "xs" },
      });

      setIsProcessing(true);
      try {
        await dcaBotsDeleteManualOrder(id, orderId);
        enqueueSnackbar(t("dcaBots.runsList.manualOrderDelete.success"), { variant: "success" });
        refreshDetail();
      } catch (error: any) {
        enqueueSnackbar(t("dcaBots.runsList.manualOrderDelete.error"), { variant: "error" });
      } finally {
        setIsProcessing(false);
      }
    } catch (err: any) {
      // silent
    }
  };

  const handleEditManualOrder = (order: IRunsListOrder) => {
    setManualOrder({
      side: order.side,
      type: order.type,
      baseCurrencyAmount: `${order.cumulativeAmount}`.replace(".", ","),
      counterCurrencyValue: `${order.cumulativeValue}`.replace(".", ","),
      time: new Date(order.filledAt ?? ""),
      note: note,
      orderId: orderId,
    });
  };

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            {isMobile ? (
              <>
                <TableCell withBorder>
                  <Typography variant="subtitle2" fontWeight={600} color={"secondary"}>
                    {t("dcaBots.runsList.typeAndStatus")}
                  </Typography>
                </TableCell>
                <TableCell withBorder>
                  <Typography textAlign={"end"} variant="subtitle2" fontWeight={600} color={"secondary"}>
                    {t("dcaBots.runsList.valueAmountAndPrice")}
                  </Typography>
                </TableCell>
              </>
            ) : (
              <>
                <TableCell withBorder>
                  <Typography variant="subtitle2" fontWeight={600} color={"secondary"}>
                    {t("dcaBots.runsList.type")}
                  </Typography>
                </TableCell>
                <TableCell withBorder>
                  <Typography variant="subtitle2" fontWeight={600} color={"secondary"}>
                    {t("dcaBots.runsList.status")}
                  </Typography>
                </TableCell>
                <TableCell withBorder>
                  <Typography variant="subtitle2" fontWeight={600} color={"secondary"}>
                    {t("dcaBots.runsList.value")}
                  </Typography>
                </TableCell>
                <TableCell withBorder>
                  <Typography variant="subtitle2" fontWeight={600} color={"secondary"}>
                    {t("dcaBots.runsList.amountAndPrice")}
                  </Typography>
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order, index: number) => (
            <React.Fragment key={`orders-${index}`}>
              <TableRow>
                {isMobile ? (
                  <>
                    <TableCell>
                      <Typography variant="subtitle2" fontWeight={400} color={"secondary"}>
                        {order.type}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontWeight={400}
                        sx={{
                          color: availableorderStatusColors[order.status as keyof typeof availableorderStatusColors],
                        }}>
                        {t(`dcaBots.runsList.dcaBotOrderStatus.${order.status}`)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <MaskedModeWrapper
                        variant="subtitle2"
                        fontWeight={400}
                        color="secondary"
                        stackProps={{ justifyContent: "end" }}
                        compact>
                        <Typography
                          textAlign={"end"}
                          variant="subtitle2"
                          fontWeight={400}
                          color={"secondary"}
                          fontFamily={"monospace"}>
                          {`${renderNumber(
                            order.cumulativeValue == 0 ? order.originalValue : order.cumulativeValue,
                            counterCurrScale
                          )}\u00a0${counterCurrency}`}
                        </Typography>
                      </MaskedModeWrapper>
                      <Stack direction={"row"} spacing={1} justifyContent={"end"}>
                        <MaskedModeWrapper
                          variant="subtitle2"
                          fontWeight={400}
                          color="secondary"
                          compact
                          stackProps={{ justifyContent: "end" }}>
                          <Typography
                            textAlign={"end"}
                            variant="subtitle2"
                            fontWeight={400}
                            color={"secondary"}
                            fontFamily={"monospace"}>
                            {`${order.side === "BUY" ? "" : "-"}${renderNumber(
                              order.status === "FILLED" ? order.cumulativeAmount : order.originalAmount,
                              currencyPairBaseScale,
                              true
                            )}\u00a0${baseCurrency}`}
                          </Typography>
                        </MaskedModeWrapper>
                        <UsdWrapper
                          value={order.averagePrice ?? order.originalPrice}
                          counterCurrency={counterCurrency}
                          baseCurrency={baseCurrency}>
                          <TypographyItem
                            textAlign={"end"}
                            variant="subtitle2"
                            fontWeight={400}
                            color={"secondary"}
                            fontFamily={"monospace"}
                            value={order.averagePrice ?? order.originalPrice}
                            scale={currencyPairPriceScale}
                            currency={counterCurrency}
                            prefix={"@\u00a0"}
                            withZeros
                          />
                        </UsdWrapper>
                      </Stack>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>
                      <Typography variant="subtitle2" fontWeight={400} color={"secondary"}>
                        {order.type}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        fontWeight={400}
                        sx={{
                          color: availableorderStatusColors[order.status as keyof typeof availableorderStatusColors],
                        }}>
                        {t(`dcaBots.runsList.dcaBotOrderStatus.${order.status}`)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <MaskedModeWrapper variant="subtitle2" fontWeight={400} color="secondary" compact>
                        <Typography variant="subtitle2" fontWeight={400} color={"secondary"} fontFamily={"monospace"}>
                          {`${renderNumber(
                            order.cumulativeValue == 0 ? order.originalValue : order.cumulativeValue,
                            counterCurrScale
                          )}\u00a0${counterCurrency}`}
                        </Typography>
                      </MaskedModeWrapper>
                    </TableCell>
                    <TableCell>
                      <Stack direction={"row"} spacing={1}>
                        <MaskedModeWrapper variant="subtitle2" fontWeight={400} color="secondary" compact>
                          <Typography variant="subtitle2" fontWeight={400} color={"secondary"} fontFamily={"monospace"}>
                            {`${order.side === "BUY" ? "" : "-"}${renderNumber(
                              order.status === "FILLED" ? order.cumulativeAmount : order.originalAmount,
                              currencyPairBaseScale,
                              true
                            )}\u00a0${baseCurrency}`}
                          </Typography>
                        </MaskedModeWrapper>
                        <UsdWrapper
                          value={order.averagePrice ?? order.originalPrice}
                          counterCurrency={counterCurrency}
                          baseCurrency={baseCurrency}>
                          <TypographyItem
                            variant="subtitle2"
                            fontWeight={400}
                            color={"secondary"}
                            fontFamily={"monospace"}
                            value={order.averagePrice ?? order.originalPrice}
                            scale={currencyPairPriceScale}
                            currency={counterCurrency}
                            prefix={"@\u00a0"}
                            withZeros
                          />
                        </UsdWrapper>
                      </Stack>
                    </TableCell>
                  </>
                )}
              </TableRow>
              {note ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography variant="subtitle2" fontWeight={400} color={"secondary"}>
                      <strong>{t("dcaBots.runsList.note")}{":"}</strong> {note}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : null}
              {(order.error || order.errorCode) && (
                <TableRow>
                  {!isMobile && <TableCell colSpan={1}></TableCell>}
                  <TableCell colSpan={3}>
                    <MaskedModeWrapper variant="subtitle2" fontWeight={600} color={colors.error}>
                      <Typography variant="subtitle2" fontWeight={600} sx={{ color: colors.error }}>
                        {`${processErrorCode(order.errorCode, order.errorParameters, order.error)}`}
                      </Typography>
                    </MaskedModeWrapper>
                  </TableCell>
                </TableRow>
              )}
              {order.triggering === OrderTriggering.MANUAL && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Box display={"flex"} justifyContent={isMobile ? "center" : "end"} pt={1}>
                      <Stack spacing={1} direction={"row"}>
                        <Button size="small" variant="outlined" color="secondary" onClick={() => handleEditManualOrder(order)}>
                          {t("dcaBots.runsList.manualOrderEdit")}
                        </Button>
                        <Button size="small" variant="outlined" color="error" onClick={handleDeleteManualOrder}>
                          {t("dcaBots.runsList.manualOrderDelete.button")}
                        </Button>
                      </Stack>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
          {priceInfo()}
        </TableBody>
      </Table>
      <BackdropLoading open={isProcessing} />
      {manualOrder && exchangeEnum && id && baseCurrency && counterCurrency && (
        <ManualOrderModal
          id={id}
          handleClose={() => setManualOrder(undefined)}
          baseCurrency={baseCurrency}
          counterCurrency={counterCurrency}
          exchangeEnum={exchangeEnum}
          refreshData={refreshDetail}
          data={manualOrder}
        />
      )}
    </>
  );
};

export default OrdersTable;
