import React, { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Collapse, IconButton, MenuItem, Slider, Stack, Typography } from "@mui/material";
import Card from "components/elements/Card";
import Select from "components/elements/Select";
import LogScale from "components/modules/DcaBotDetail/Balance/TradeModal/LimitBuy/LogScale";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { BuyBackModeType } from "types/enums";

import { ExitStrategyFormikValues } from "../module/useExitStrategyData";

const BuyBack: React.FC = () => {
  const { t } = useTranslation();
  const formik = useFormikContext<ExitStrategyFormikValues>();
  const [isCollaped, setIsCollapsed] = useState<boolean>(false);

  return (
    <Card
      header={t("exitStrategy.create.buyBack.title")}
      subheader={t("exitStrategy.create.buyBack.subtitle")}
      headerProps={{ fontWeight: 500, variant: "body1" }}
      subheaderProps={{ fontWeight: 500, variant: "body2" }}
      sx={{ mx: "auto", mb: 4 }}
      leftActions={
        <IconButton size="small" onClick={() => setIsCollapsed(!isCollaped)}>
          {isCollaped ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      }>
      <Collapse in={isCollaped} timeout="auto">
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
              {t("exitStrategy.create.buyBack.buyBackModeTitle")}
            </Typography>
            <Typography variant="caption" fontWeight={400} pb={2}>
              {t("exitStrategy.create.buyBack.buyBackModeDescription")}
            </Typography>
            <Select
              fullWidth
              id="buyBackMode"
              name="buyBackMode"
              label={t("exitStrategy.create.buyBack.buyBackMode")}
              value={formik.values.buyBackMode}
              onChange={formik.handleChange}>
              {Object.values(BuyBackModeType).map((type) => (
                <MenuItem key={type} value={type}>
                  {t(`exitStrategy.create.buyBack.types.${type}`)}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          {formik.values.buyBackMode !== BuyBackModeType.NONE && (
            <>
              <Stack spacing={1}>
                <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                  {t("exitStrategy.create.buyBack.buyBackPriceStepPctTitle")}
                </Typography>
                <Typography variant="caption" fontWeight={400}>
                  {t("exitStrategy.create.buyBack.buyBackPriceStepPctDescription")}
                </Typography>
                <Box pt={4} px={1}>
                  <LogScale
                    value={formik.values.buyBackPriceStepPct}
                    defaultValue={formik.values.buyBackPriceStepPct}
                    min={5}
                    step={1}
                    max={90}
                    valueLabelFormat={(n: number) => `-${n} %`}
                    onChange={(value) => formik.setFieldValue("buyBackPriceStepPct", value)}
                    valueLabelDisplay={"on"}
                    sx={{
                      color: "success.main",
                    }}
                  />
                </Box>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                  {t("exitStrategy.create.buyBack.buyBackValuePctTitle")}
                </Typography>
                <Typography variant="caption" fontWeight={400}>
                  {t("exitStrategy.create.buyBack.buyBackValuePctDescription")}
                </Typography>
                <Box pt={4} px={1}>
                  <Slider
                    value={formik.values.buyBackValuePct}
                    min={1}
                    step={1}
                    max={200}
                    valueLabelDisplay="on"
                    onChange={(_e, value) => formik.setFieldValue("buyBackValuePct", value as number)}
                    valueLabelFormat={(n: number) => `${n} %`}
                    sx={{
                      color: "success.main",
                    }}
                  />
                </Box>
              </Stack>
            </>
          )}
        </Stack>
      </Collapse>
    </Card>
  );
};

export default BuyBack;
