import { useState } from "react";

import { updateUserInvoiceData } from "API/calls";
import { useUserDataContext } from "context/UserDataContext";
import useConfirm from "hooks/useConfirm";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IInvoiceData } from "types/types";

import ModalInvoiceSetup from "./ModalInvoiceSetup";

interface IProps {
  onClose: () => void;
  onSuccess?: () => void;
}

const InvoiceSetup = ({ onClose, onSuccess }: IProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { confirm } = useConfirm();

  const { creditsRechargeType, refreshCreditsRecharge } = useUserDataContext();
  const [modalInvoiceSetupOpen, setModalInvoiceSetupOpen] = useState(true);

  const handleUpdateUserInvoiceData = async (values: IInvoiceData) => {
    try {
      await updateUserInvoiceData(values);
      enqueueSnackbar(t("settingsPage.invoiceSetupModal.success"), { variant: "success" });
      onSuccess?.();
    } catch (err: any) {
      enqueueSnackbar(err?.response?.data?.message ?? t("commonError"), { variant: "error" });
    }
  };

  const handleInvoiceSetup = async (values: IInvoiceData) => {
    if (creditsRechargeType) {
      setModalInvoiceSetupOpen(false);
      try {
        await confirm({
          title: t("settingsPage.invoiceSetupRechargeModal.title"),
          description: t("settingsPage.invoiceSetupRechargeModal.description"),
          dialogProps: { maxWidth: "sm" },
          cancellationText: t("settingsPage.invoiceSetupRechargeModal.cancel"),
          confirmationText: t("settingsPage.invoiceSetupRechargeModal.confirm"),
          allowClose: false,
        });
        await handleUpdateUserInvoiceData(values);
        refreshCreditsRecharge?.();
        onClose()
      } catch (err: any) {
        onClose();
      }
    } else {
      await handleUpdateUserInvoiceData(values);
      onClose();
    }
  };

  return (
    <>
      {modalInvoiceSetupOpen && (
        <ModalInvoiceSetup open={modalInvoiceSetupOpen} onClose={onClose} onSuccess={handleInvoiceSetup} />
      )}
    </>
  );
};

export default InvoiceSetup;
