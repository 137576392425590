import { useState } from "react";

import { Box, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { dcaBotsDeleteExitStrategy } from "API/calls";
import Card from "components/elements/Card";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import useConfirm from "hooks/useConfirm";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IExitStrategy } from "types/types";
import { splitCurrencyPair } from "utils";
import { renderPrice } from "utils/formatter";

interface IProps {
  id: string;
  refetch: () => void;
  data: IExitStrategy;
  currencyPair: string;
  exchangeEnum: string;
}

const ExitStrategy = ({ id, refetch, data, currencyPair, exchangeEnum }: IProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { confirm } = useConfirm();
  const { baseCurrency, counterCurrency } = splitCurrencyPair(currencyPair);
  const { counterCurrDisplayedScale, currencyPairBaseScale, baseCurrDisplayedScale } = useExchangesMetadata(
    exchangeEnum,
    currencyPair
  );
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleRemove = async () => {
    try {
      await confirm({
        title: t("dcaBots.exitStrategy.remove.areYouSure"),
        description: t("dcaBots.exitStrategy.remove.description"),
        dialogProps: { maxWidth: "xs" },
        confirmationText: t("dcaBots.exitStrategy.remove.confirm"),
        cancellationText: t("dcaBots.exitStrategy.remove.cancel"),
      });

      try {
        setIsProcessing(true);
        await dcaBotsDeleteExitStrategy(id);
        refetch();
        enqueueSnackbar(t("dcaBots.exitStrategy.remove.success"), { variant: "success" });
      } catch (error: any) {
        enqueueSnackbar(t("dcaBots.exitStrategy.remove.error"), { variant: "error" });
        setIsProcessing(false);
      }
    } catch (err: any) {
      // silent
    }
  };

  return (
    <Card
      sx={{ mb: 0 }}
      header={t("dcaBots.exitStrategy.title")}
      actions={
        <Button color="error" variant="outlined" onClick={handleRemove} size="small" disabled={isProcessing}>
          {t("dcaBots.exitStrategy.remove.button")}
        </Button>
      }>
      <Stack spacing={1}>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.type")}:
          </Typography>
          <Typography variant="body2" color={"secondary"} fontWeight={600}>
            {t(`dcaBots.exitStrategy.types.${data.strategyParams.type}`)}
          </Typography>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.totalAmount")}:
          </Typography>
          <MaskedModeWrapper variant="body2" color={"secondary"} fontWeight={600}>
            <Typography variant="body2" color={"secondary"} fontWeight={600}>
              {renderPrice(data.strategyParams.totalAmount, baseCurrency, baseCurrDisplayedScale)}
            </Typography>
          </MaskedModeWrapper>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.firstSellingPrice")}:
          </Typography>
          <Typography variant="body2" color={"secondary"} fontWeight={600}>
            {renderPrice(data.strategyParams.firstSellingPrice, counterCurrency, counterCurrDisplayedScale, true)}
          </Typography>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.highestSellingPricePct")}:
          </Typography>
          <Typography variant="body2" color={"secondary"} fontWeight={600}>
            {data.strategyParams.highestSellingPricePct}%
          </Typography>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.sellingPriceStepPct")}:
          </Typography>
          <Typography variant="body2" color={"secondary"} fontWeight={600}>
            {data.strategyParams.sellingPriceStepPct}%
          </Typography>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.pctOfGrowthToSell")}:
          </Typography>
          <Typography variant="body2" color={"secondary"} fontWeight={600}>
            {data.strategyParams.pctOfGrowthToSell}%
          </Typography>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.buyBackMode")}:
          </Typography>
          <Typography variant="body2" color={"secondary"} fontWeight={600}>
            {t(`dcaBots.exitStrategy.buyBackModes.${data.strategyParams.buyBackMode}`)}
          </Typography>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.buyBackPriceStepPct")}:
          </Typography>
          <Typography variant="body2" color={"secondary"} fontWeight={600}>
            {data.strategyParams.buyBackPriceStepPct}%
          </Typography>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.buyBackValuePct")}:
          </Typography>
          <Typography variant="body2" color={"secondary"} fontWeight={600}>
            {data.strategyParams.buyBackValuePct}%
          </Typography>
        </Stack>
      </Stack>
      <Box mt={2}>
        <Typography variant="body1" color="secondary" fontWeight={600}>
          {t("dcaBots.exitStrategy.sellOrders")}
        </Typography>
        <Box px={{ xs: 1, md: 3 }} sx={{ overflowX: "auto", whiteSpace: "nowrap" }}>
          {data?.sellOrders?.map((order, index) => (
            <Stack spacing={2} key={order.price + index} justifyContent={"space-between"} direction={"row"}>
              <Typography fontFamily={"monospace"} variant="body1" fontWeight={400}>
                {renderPrice(order.price, counterCurrency, counterCurrDisplayedScale, true)}
              </Typography>
              <MaskedModeWrapper variant="body1" fontWeight={400}>
                <Typography fontFamily={"monospace"} variant="body1" fontWeight={400}>
                  {renderPrice(order.amount, baseCurrency, currencyPairBaseScale, true)}
                </Typography>
              </MaskedModeWrapper>
              <MaskedModeWrapper variant="body1" fontWeight={400}>
                <Typography fontFamily={"monospace"} variant="body1" fontWeight={400}>
                  {renderPrice(order.price * order.amount, counterCurrency, counterCurrDisplayedScale, true)}
                </Typography>
              </MaskedModeWrapper>
              <Typography variant="body1" fontWeight={400}>
                {t(`exitStrategy.create.summary.${order.status}`)}
              </Typography>
            </Stack>
          ))}
        </Box>
      </Box>
      {data.buyOrders && data.buyOrders.length > 0 && (
        <Box mt={2}>
          <Typography variant="body1" color="secondary" fontWeight={600}>
            {t("dcaBots.exitStrategy.buyOrders")}
          </Typography>
          <Box px={{ xs: 1, md: 3 }} sx={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            {data.buyOrders.map((order, index) => (
              <Stack spacing={2} key={order.price + index} justifyContent={"space-between"} direction={"row"}>
                <Typography fontFamily={"monospace"} variant="body1" fontWeight={400}>
                  {renderPrice(order.price, counterCurrency, counterCurrDisplayedScale, true)}
                </Typography>
                <MaskedModeWrapper variant="body1" fontWeight={400}>
                  <Typography fontFamily={"monospace"} variant="body1" fontWeight={400}>
                    {renderPrice(order.value / order.price, baseCurrency, currencyPairBaseScale, true)}
                  </Typography>
                </MaskedModeWrapper>
                <MaskedModeWrapper variant="body1" fontWeight={400}>
                  <Typography fontFamily={"monospace"} variant="body1" fontWeight={400}>
                    {renderPrice(order.value, counterCurrency, counterCurrDisplayedScale, true)}
                  </Typography>
                </MaskedModeWrapper>
                <Typography variant="body1" fontWeight={400}>
                  {t(`exitStrategy.create.summary.ACTIVE`)}
                </Typography>
              </Stack>
            ))}
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default ExitStrategy;
