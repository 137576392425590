import { useState } from "react";

import AddchartIcon from "@mui/icons-material/Addchart";
import CreateIcon from "@mui/icons-material/Create";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Alert, AlertTitle, Box, Collapse, Stack, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Badge from "components/elements/Badge";
import { BadgeColors } from "components/elements/Badge/types";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import { CoinsStacked, CoinsSwap2 } from "components/icons";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { Trans, useTranslation } from "react-i18next";
import { DcaBotRunState, OrderTriggering, Side } from "types/enums";
import { IRunsListOrder } from "types/types";
import { renderDateTimeWithComma, renderNumber } from "utils/formatter";

import OrdersTable from "../OrdersTable";
import { ExpandMore, ItemBox } from "./styles";
import IProps from "./types";

const botRunStateToColor = {
  SCHEDULED: "info" as BadgeColors,
  STARTED: "info" as BadgeColors,
  OPEN: "info" as BadgeColors,
  CLOSING: "info" as BadgeColors,
  DONE: "success" as BadgeColors,
  ERROR: "error" as BadgeColors,
};
const includeToSums = ["FILLED", "PARTIALLY_FILLED", "PARTIALLY_CANCELED"];

const OrderRow = ({ dcaBotRun, baseCurrency, counterCurrency, defaultOpen, exchangeEnum, refreshDetail }: IProps) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(defaultOpen ?? false);
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { counterCurrDisplayedScale, currencyPairBaseScale } = useExchangesMetadata(
    exchangeEnum ?? "",
    `${baseCurrency}/${counterCurrency}`
  );
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(300));

  const calcSumsByOrders = (orders: IRunsListOrder[]) => {
    if (orders.length === 0) return undefined;

    let baseSums = 0;
    let counterSums = 0;

    orders.forEach((order) => {
      if (includeToSums.includes(order.status)) {
        baseSums += order.cumulativeAmount;
        counterSums += order.cumulativeValue;
      }
    });

    if (baseSums === 0 || counterSums === 0) return undefined;

    return {
      base: baseSums,
      counter: counterSums,
    };
  };
  const sums = calcSumsByOrders(dcaBotRun.orders);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let orderColor = "success.main";
  let orderLabel = t(`dcaBots.runsList.purchased`);
  let orderMobileSign = "+";
  if (dcaBotRun.side === Side.SELL) {
    orderColor = "error";
    orderLabel = t(`dcaBots.runsList.sold`);
    orderMobileSign = "-";
  }

  const timestamp = dcaBotRun.startedAt ?? dcaBotRun.scheduledAt;

  const getCardSubheader = () => {
    if (dcaBotRun.orders && dcaBotRun.orders[0]?.dynamicStrategyData) {
      const { dynamicStrategyType, multiplier } = dcaBotRun.orders[0].dynamicStrategyData;

      return (
        <Box pb={"5px"} pt={"4px"} px={"16px"}>
          <Typography variant="caption">
            <Trans
              i18nKey={"dcaBots.runsList.dynamicStrategySubtitle"}
              values={{
                type: t(`dcaBots.create.dynamicStrategyLabel.types.${dynamicStrategyType}`),
                multiplier: renderNumber(multiplier, 2),
              }}
            />
          </Typography>
        </Box>
      );
    }
  };

  const getState = () => {
    if (dcaBotRun.state === DcaBotRunState.DONE && dcaBotRun.triggering === OrderTriggering.MANUAL) {
      return DcaBotRunState.MANUAL_DONE;
    }

    return dcaBotRun.state;
  };

  const getIcon = () => {
    switch (dcaBotRun.triggering) {
      case OrderTriggering.PERIODIC:
        return <CoinsStacked color="inherit" sx={{ fontSize: "inherit" }} />;
      case OrderTriggering.INSTANT:
        return <AddchartIcon fontSize="inherit" color="inherit" />;
      case OrderTriggering.MANUAL:
        return <CreateIcon fontSize="inherit" color="inherit" />;
      case OrderTriggering.EXIT:
        return <CoinsSwap2 fontSize="inherit" color="inherit" />;
    }
  };

  return (
    <ItemBox>
      <Stack width={"100%"}>
        <Stack width={"100%"} direction="row" justifyContent="space-between">
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2 }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            justifyContent={"center"}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              {getIcon()}
              {!!timestamp && (
                <Typography variant="body2" fontFamily={"monospace"}>
                  {renderDateTimeWithComma(timestamp)}
                </Typography>
              )}
            </Stack>
            <Badge color={botRunStateToColor[dcaBotRun.state as keyof typeof botRunStateToColor]}>
              <Typography variant="caption">{t(`dcaBots.runsList.dcaBotRunState.${getState()}`)}</Typography>
            </Badge>
          </Stack>
          <Stack direction="row" spacing={1} alignItems={"center"}>
            {sums && (
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={0.5}
                alignItems={{ xs: "flex-end", sm: "center" }}
                justifyContent={"center"}>
                {!isMobile && (
                  <Typography variant="body2" color={orderColor} fontFamily={"monospace"}>
                    {orderLabel}
                  </Typography>
                )}
                <MaskedModeWrapper variant="body2" fontWeight={600} color={orderColor} compact>
                  <Typography variant="body2" fontWeight={600} color={orderColor} fontFamily={"monospace"}>
                    {isMobile && orderMobileSign}
                    {`${renderNumber(sums.base, currencyPairBaseScale, true)}\u00a0${baseCurrency}`}
                  </Typography>
                </MaskedModeWrapper>
                <Stack
                  direction={isSmallScreen ? "column" : "row"}
                  spacing={isSmallScreen ? 0 : 0.5}
                  alignItems={"flex-end"}>
                  <Typography variant="body2" fontFamily={"monospace"}>
                    {t(`dcaBots.runsList.for`)}
                  </Typography>
                  <MaskedModeWrapper variant="body2" fontWeight={600} compact>
                    <Typography variant="body2" fontWeight={600} fontFamily={"monospace"}>
                      {`${renderNumber(sums.counter, counterCurrDisplayedScale, true)}\u00a0${counterCurrency}`}
                    </Typography>
                  </MaskedModeWrapper>
                </Stack>
              </Stack>
            )}
            <ExpandMore expand={expanded} onClick={handleExpandClick} style={{ cursor: "pointer", padding: 0 }}>
              <KeyboardArrowDownIcon />
            </ExpandMore>
          </Stack>
        </Stack>
        <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ mt: 3, overflowX: "auto" }}>
          {dcaBotRun.state === DcaBotRunState.SCHEDULED ? (
            <Alert severity="info">
              <AlertTitle>{t("dcaBots.runsList.scheduledRunInfo.title")}</AlertTitle>
              {t("dcaBots.runsList.scheduledRunInfo.description")}
            </Alert>
          ) : (
            <OrdersTable
              orders={dcaBotRun.orders}
              baseCurrency={baseCurrency}
              counterCurrency={counterCurrency}
              exchangeEnum={exchangeEnum}
              price={dcaBotRun.side === Side.BUY ? dcaBotRun.askPrice : dcaBotRun.bidPrice}
              refreshDetail={refreshDetail}
              orderId={dcaBotRun.id}
              note={dcaBotRun.note}
              isRunManual={dcaBotRun.triggering === OrderTriggering.MANUAL}
            />
          )}
          {getCardSubheader()}
        </Collapse>
      </Stack>
    </ItemBox>
  );
};

export default OrderRow;
