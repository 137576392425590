import React, { useState } from "react";

import { Box, Button, Grid, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import BackdropLoading from "components/elements/BackdropLoading";
import Card from "components/elements/Card";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import Select from "components/elements/Select";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { FormikProvider } from "formik";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { Trans, useTranslation } from "react-i18next";
import { ExitStrategyType, SellOrderStatus } from "types/enums";
import { IDcaBotDetail } from "types/types";
import { splitCurrencyPair } from "utils";
import { renderNumber, renderPrice } from "utils/formatter";

import Balance from "./Balance";
import BuyBack from "./BuyBack";
import useExitStrategyData from "./module/useExitStrategyData";
import PctOfGrowthSell from "./PctOfGrowthSell";
import ReviewCard from "./ReviewCard";

interface IProps {
  id: string;
  data: IDcaBotDetail;
  enabledExitStrategies: ExitStrategyType[];
}

const ExitStrategie: React.FC<IProps> = ({ id, data, enabledExitStrategies }) => {
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();
  const [type, setType] = useState<ExitStrategyType | null>(null);

  const { exchangeConnection, currencyPair } = data;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const details = data.details!;

  const { baseCurrency, counterCurrency } = splitCurrencyPair(currencyPair);
  const { counterCurrDisplayedScale, baseCurrDisplayedScale, currencyPairPriceScale, currencyPairBaseScale }  = useExchangesMetadata(exchangeConnection.exchangeEnum, currencyPair);
  const { formik, previewData, isProcessing } = useExitStrategyData({
    details,
    baseCurrency,
    counterCurrency,
    currencyPairPriceScale,
    id,
    type,
  });

  const getcurrentValueCardColor = () =>
    details.currentValue !== undefined && details.currentValue >= 0 ? colors.success : colors.error;

  const getPercentDifference = () => {
    const preffix = details.currentValuePercentDifference >= 0 ? "+" : "-";
    const color = details.currentValuePercentDifference >= 0 ? colors.success : colors.error;

    return (
      <Typography variant="body1" fontWeight={400} color={color}>
        {`${preffix} ${renderNumber(Math.abs(details.currentValuePercentDifference), 2, true)}%`}
      </Typography>
    );
  };

  const getValueDifference = () => {
    const value = details.currentValueDifference;
    const preffix = value >= 0 ? "+" : "-";

    return (
      <MaskedModeWrapper variant="h6" fontWeight={600} color="rgb(109, 109, 109)">
        <Typography variant="h6" fontWeight={600} color="rgb(109, 109, 109)">
          {`${preffix} ${renderNumber(Math.abs(value), counterCurrDisplayedScale, true)} ${counterCurrency}`}
        </Typography>
      </MaskedModeWrapper>
    );
  };

  return (
    <Box width={"100%"}>
      <Card header={t("exitStrategy.title")} sx={{ mx: "auto", mb: 4 }}>
        <Grid container rowSpacing={4} columnSpacing={5}>
          {/* START OVERVIEW */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                  {t("exitStrategy.create.overview")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6}>
                <ReviewCard
                  label={t("exitStrategy.create.totalInvested")}
                  color={theme.palette.tertiary.main}
                  amount={details.totalInvested}
                  scale={counterCurrDisplayedScale}
                  currency={counterCurrency}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6}>
                <ReviewCard
                  label={t("exitStrategy.create.currentValue")}
                  color={getcurrentValueCardColor()}
                  amount={details.currentValue}
                  scale={counterCurrDisplayedScale}
                  currency={counterCurrency}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6}>
                <Stack width={"100%"} height={"100%"} justifyContent={"center"} alignItems={"center"}>
                  {getPercentDifference()}
                  {getValueDifference()}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6}>
                <ReviewCard
                  label={t("exitStrategy.create.youHaveSaved")}
                  color={theme.palette.primary.main}
                  amount={details.totalAmount}
                  scale={baseCurrDisplayedScale}
                  currency={baseCurrency}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" fontWeight={400}>
                  <Trans i18nKey="exitStrategy.create.setInitialAmount" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* END OVERVIEW */}
          {/* START BALANCE */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                  {t("exitStrategy.create.balanceInfo")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Balance
                  id={id}
                  exchangeTitle={exchangeConnection?.exchangeTitle ?? ""}
                  exchangeLabel={exchangeConnection?.label}
                  exchangeEnum={exchangeConnection?.exchangeEnum}
                  isBrokerage={exchangeConnection?.isBrokerage}
                  isExchangeEnabled={exchangeConnection?.isExchangeEnabled}
                  functional={exchangeConnection?.functional}
                  baseCurrency={baseCurrency}
                  counterCurrency={counterCurrency}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* END BALANCE */}
          {/* START SELECT TYPE */}
          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                {t("exitStrategy.create.selectType")}
              </Typography>
              <Typography variant="caption" fontWeight={400} pb={2}>
                {t("exitStrategy.create.typeDescription")}
              </Typography>
              <Select
                id="type"
                name="type"
                fullWidth
                label={t("exitStrategy.create.type")}
                value={type ?? ""}
                onChange={(e) => setType(e.target.value as ExitStrategyType)}>
                {enabledExitStrategies.map((strategy) => (
                  <MenuItem key={strategy} value={strategy}>
                    {t(`exitStrategy.create.types.${strategy}`)}
                  </MenuItem>
                ))}
                <MenuItem key={"other"} disabled>
                  {t("exitStrategy.create.otherType")}
                </MenuItem>
              </Select>
            </Stack>
          </Grid>
          {/* END SELECT TYPE */}
          {/* START PCT OF GROWTH SELL */}
          <FormikProvider value={formik}>
            {type === ExitStrategyType.PCT_OF_GROWTH_SELL && (
              <PctOfGrowthSell
                details={details}
                currencyPair={currencyPair}
                exchangeEnum={exchangeConnection.exchangeEnum}
                previewData={previewData}
              />
            )}
          </FormikProvider>
          {/* END PCT OF GROWTH SELL */}
        </Grid>
      </Card>
      {type === ExitStrategyType.PCT_OF_GROWTH_SELL && (
        <FormikProvider value={formik}>
          <BuyBack />
        </FormikProvider>
      )}
      {type !== null && (
        <Card
          header={t("exitStrategy.create.summary.title")}
          headerProps={{ fontWeight: 500, variant: "body1" }}
          sx={{ mx: "auto" }}>
          <Stack spacing={2}>
            <Box px={{ xs: 1, md: 3 }} sx={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              {previewData?.sellOrders?.map((order, index) => (
                <Stack spacing={2} key={order.price + index} justifyContent={"space-between"} direction={"row"}>
                  <Typography fontFamily={"monospace"} variant="body1" fontWeight={400}>
                    {renderPrice(order.price, counterCurrency, counterCurrDisplayedScale, true)}
                  </Typography>
                  <Typography fontFamily={"monospace"} variant="body1" fontWeight={400}>
                    {renderPrice(order.amount, baseCurrency, currencyPairBaseScale, true)}
                  </Typography>
                  <Typography fontFamily={"monospace"} variant="body1" fontWeight={400}>
                    {renderPrice(order.price * order.amount, counterCurrency, counterCurrDisplayedScale, true)}
                  </Typography>
                  <Typography variant="body1" fontWeight={400}>
                    {t(`exitStrategy.create.summary.${order.status}`)}
                  </Typography>
                </Stack>
              ))}
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                onClick={() => formik.handleSubmit()}
                disabled={previewData?.sellOrders.every(
                  (order) => order.status === SellOrderStatus.NOT_ENOUGH_BALANCE
                )}>
                {t("exitStrategy.create.summary.create")}
              </Button>
            </Box>
          </Stack>
          <BackdropLoading open={isProcessing} />
        </Card>
      )}
    </Box>
  );
};

export default ExitStrategie;
