import Big from "big.js";
import { t } from "i18next";

export function renderDateCustom(date: string, options: Intl.DateTimeFormatOptions) {
  return new Intl.DateTimeFormat("cs-CZ", options).format(new Date(date));
}

export function renderLocaleDate(date: string | number, withTime = false) {
  return new Intl.DateTimeFormat(undefined, {
    dateStyle: "medium",
    timeStyle: withTime ? "short" : undefined,
  }).format(new Date(date));
}

export function renderDate(date: string) {
  return new Intl.DateTimeFormat("cs-CZ", {
    dateStyle: "medium",
  }).format(new Date(date));
}

export function renderDateTime(date: string) {
  return new Intl.DateTimeFormat("cs-CZ", {
    dateStyle: "medium",
    timeStyle: "short",
  }).format(new Date(date));
}

export function renderDateTimeWithComma(date: string) {
  const onlyDate = new Intl.DateTimeFormat("cs-CZ", {
    dateStyle: "medium",
  }).format(new Date(date));

  const onlyTime = new Intl.DateTimeFormat("cs-CZ", {
    timeStyle: "short",
  }).format(new Date(date));

  return `${onlyDate}, ${onlyTime}`;
}

export function renderTime(date: string) {
  return new Intl.DateTimeFormat("cs-CZ", {
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(date));
}

export function renderCredits(price?: number) {
  if (price === undefined) {
    return "";
  }

  return `${new Intl.NumberFormat("cs-CZ", {}).format(price)} Štosáků`;
}

export function renderPrice(price: number, currency?: string, decimalPlaces = 0, withZeros = false) {
  if (currency === undefined || ["CZK", "USD", "EUR"].includes(currency)) {
    return new Intl.NumberFormat("cs-CZ", {
      style: "currency",
      currency: currency ?? "CZK",
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: withZeros ? decimalPlaces : 0,
    }).format(price);
  } else {
    return `${new Intl.NumberFormat("cs-CZ", {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: withZeros ? decimalPlaces : 0,
    }).format(price)} ${currency}`;
  }
}

export function renderNumber(price: number, decimalPlaces = 2, withZeros = false) {
  return new Intl.NumberFormat("cs-CZ", {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: withZeros ? decimalPlaces : undefined,
  }).format(price);
}

export function renderBadges(badges: string) {
  return badges;
}

export function renderEUR(number: number) {
  return number + " €";
}

export function toHoursAndMinutes(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours > 0 ? ` ${hours}h` : ""}${minutes > 0 ? ` ${minutes}m` : ""}`;
}

export function getBigWithComma(value: Big) {
  return value.toString().replace(".", ",");
}

export function getNumberWithComma(value?: number | string, defaultValue = "0") {
  if(!value) return defaultValue;

  return `${value}`.replace(".", ",")
}

export const getExchangeConnectionLabel = (exchangeTitle: string, functional: boolean, label?: string, separator = ": ") => {
  const result = label ? `${exchangeTitle}${separator}${label}` : exchangeTitle;

  return functional ? result : `${result} - ${t("exchangeConnections.functionalError.brokenOptionSuffix")}`;
};
