import { FC, useEffect, useState } from "react";

import { Box } from "@mui/material";
import CandleChart, { convertOHLCData } from "components/elements/CandleChart";
import { IPriceRange } from "components/elements/CandleChart/types";
import CenterWrapper from "components/elements/CenterWrapper";
import Loading from "components/modules/Loading";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useAPIOHLC } from "hooks/useAPI";
import useCurrencyPairs from "hooks/useCurrencyPairs";
import useExchangeRate from "hooks/useExchangeRate";
import { OHLCInterval, SellOrderStatus } from "types/enums";
import { ICurrencyPair, IPreviewExitStrategy } from "types/types";
import { decreaseByPercentage } from "utils";

interface IProps {
  exchangeEnum: string;
  currencyPair: string;
  scale: number;
  counterCurrency: string;
  data?: IPreviewExitStrategy;
}

const Chart: FC<IProps> = ({ exchangeEnum, currencyPair, scale, counterCurrency, data }) => {
  const { colors } = useMUIThemeModeContext();
  const { showInUSD } = useUserDataContext();
  const { getUSDOriginalPrice } = useExchangeRate();
  const [isAvailable, setIsAvailable] = useState<boolean>(false);

  const [priceLines, setPriceLines] = useState<any[]>([]);
  const [priceRange, setPriceRange] = useState<IPriceRange>();

  const { data: currencyPairs } = useCurrencyPairs();
  const { data: OHLCData } = useAPIOHLC(currencyPair, OHLCInterval.ONE_DAY, exchangeEnum, undefined, isAvailable);

  useEffect(() => {
    if (currencyPairs) {
      const exCurrPairs = currencyPairs.find((pairs) => pairs?.id === exchangeEnum);
      const currPair = exCurrPairs?.data?.find((pair: ICurrencyPair) => pair?.key === currencyPair);

      if (currPair?.enabled && currPair.ohlc_data) setIsAvailable(true);
    }
  }, [currencyPairs, exchangeEnum, currencyPair]);

  const priceTransform = (price: number) => {
    const inUSD = getUSDOriginalPrice(counterCurrency, price);
    return inUSD ?? price;
  };

  useEffect(() => {
    if (data && data.sellOrders && data.sellOrders.length > 0) {
      const result: any[] = [];

      data.sellOrders.forEach((order, index) => {
        const value = order.price;
        const price = showInUSD ? priceTransform(value) : value;
        const labelVisible = index === 0 || index === data.sellOrders.length - 1;

        result.push({
          price,
          color: colors.error,
          lineWidth: 1.5,
          lineStyle: order.status === SellOrderStatus.ENOUGH_BALANCE ? 0 : 2,
          axisLabelVisible: labelVisible,
        });
      });

      setPriceLines(result);

      const priceLineValues = result.map((line) => line.price);
      const maxPrice = Math.max(...priceLineValues);
      const minPrice = Math.min(...priceLineValues);

      setPriceRange({ minValue: decreaseByPercentage(minPrice, 50), maxValue: maxPrice });
    }
  }, [JSON.stringify(data), showInUSD]);

  if (!isAvailable) return null;

  if (!OHLCData) {
    return (
      <CenterWrapper>
        <Loading />
      </CenterWrapper>
    );
  }

  return (
    <Box sx={{ height: "300px" }}>
      <CandleChart
        data={convertOHLCData(OHLCData, showInUSD ? priceTransform : undefined)}
        dateWithTime={false}
        pricePrecision={scale}
        priceMarginBottom={0.2}
        priceMarginTop={0.05}
        priceLines={priceLines}
        priceRange={priceRange}
        lastValueVisible={true}
      />
    </Box>
  );
};

export default Chart;
