import React from "react";

import { Add, Remove } from "@mui/icons-material";
import { InputAdornment, IconButton, styled, alpha, Stack } from "@mui/material";
import NumberField from "components/elements/NumberField";

interface StepInputProps {
  id: string;
  formik: any;
  step: number;
  min: number;
  max: number;
  suffix: string;
}

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,

  "&.Mui-disabled": {
    color: theme.palette.common.white,
    backgroundColor: "#C4C4C4",
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  },
}));

const StepInput: React.FC<StepInputProps> = ({ id, formik, step, min, max, suffix }) => {
  const value = Number(formik.values[id]);

  const handleIncrease = () => {
    const newValue = value + step;
    if (max === undefined || newValue <= max) {
      formik.setFieldValue(id, newValue);
    }
  };

  const handleDecrease = () => {
    const newValue = value - step;
    if (min === undefined || newValue >= min) {
      formik.setFieldValue(id, newValue);
    }
  };

  const handleBlur = () => {
    if (value === undefined) return;

    if (value < min) formik.setFieldValue(id, min);
    else if (value > max) formik.setFieldValue(id, max);
  };

  return (
    <Stack direction={"row"} spacing={2}>
      <NumberField
        id={id}
        name={id}
        value={value}
        autoComplete="off"
        onlyInteger
        onlyPositive
        onChange={formik.handleChange}
        onBlur={handleBlur}
        fullWidth
        inputProps={{
          step: 1,
          min: min,
          max: max,
          type: "number",
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
        }}
      />
      <Stack spacing={1} direction={"row"} alignContent={"center"} alignItems={"center"}>
        <CustomIconButton onClick={handleDecrease} disabled={value <= min}>
          <Remove />
        </CustomIconButton>
        <CustomIconButton onClick={handleIncrease} disabled={value >= max}>
          <Add />
        </CustomIconButton>
      </Stack>
    </Stack>
  );
};

export default StepInput;
