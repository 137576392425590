import React from "react";

import { alpha, Box, Stack, Typography } from "@mui/material";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import { AccountingCoinsStack } from "components/icons";
import SatsView from "components/modules/SatsView";

interface ReviewCardProps {
  label: string;
  color: string;
  amount: number;
  scale: number;
  currency: string;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ label, color, amount, currency, scale }) => {
  return (
    <Box
      bgcolor={alpha(color, 0.1)}
      borderRadius="10px"
      color={color}
      display="flex"
      alignItems="center"
      width={"100%"}
      p={1.5}>
      <Stack>
        <Typography variant="body1" fontWeight={600} sx={{ textTransform: "none" }}>
          {label}
        </Typography>
        <Stack width={"100%"} direction={"row"} alignItems={"center"}>
          <AccountingCoinsStack sx={{ fontSize: 24, mr: 1 }} />
          <MaskedModeWrapper color={color} variant={"h6"}>
            <SatsView
              variant="h6"
              color={color}
              value={amount}
              withZeros={!!amount}
              scale={scale}
              currency={currency}
            />
          </MaskedModeWrapper>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ReviewCard;
