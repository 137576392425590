import { Grid, Stack, Typography, useTheme } from "@mui/material";
import Big from "big.js";
import CenterWrapper from "components/elements/CenterWrapper";
import Loading from "components/modules/Loading";
import { useFormikContext } from "formik";
import { useAPICurrencyPairsTicker } from "hooks/useAPI";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation } from "react-i18next";
import { IDetails, IPreviewExitStrategy } from "types/types";
import { increaseByPercentage, splitCurrencyPair } from "utils";
import { renderNumber } from "utils/formatter";

import Chart from "../Chart";
import { ExitStrategyFormikValues } from "../module/useExitStrategyData";
import InputWithLog from "./InputWithLog";
import InputWithSlider from "./InputWithSlider";
import StepInput from "./StepInput";

interface IProps {
  details: IDetails;
  currencyPair: string;
  exchangeEnum: string;
  previewData?: IPreviewExitStrategy;
}

const PctOfGrowthSell = ({ details, currencyPair, exchangeEnum, previewData }: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const formik = useFormikContext<ExitStrategyFormikValues>();
  const { baseCurrency, counterCurrency } = splitCurrencyPair(currencyPair);
  const { baseCurrScale, currencyPairPriceScale } = useExchangesMetadata(exchangeEnum, currencyPair);
  const { data } = useAPICurrencyPairsTicker(`${baseCurrency}-${counterCurrency}`, exchangeEnum);
  const { getUSDPrice } = useExchangeRate();

  const getApproxUsdPrice = (value: string | number, currency: string) => {
    const price = getUSDPrice(currency, value, 0);
    return price ? `~ ${price}` : undefined;
  };

  const getBidPrice = (price: number, currency: string) => {
    const usdPrice = getUSDPrice(currency, price, 0);

    if (usdPrice) {
      return <Typography fontWeight={600} fontSize={"inherit"}>{`(~\u00a0${usdPrice})`}</Typography>;
    }
  };

  if (!data) {
    return (
      <CenterWrapper>
        <Loading />
      </CenterWrapper>
    );
  }

  const getHighestSellingPrice = () => {
    const fromPrice = Number(formik.values.firstSellingPrice ? `${formik.values.firstSellingPrice}`.replace(",", ".") : 0);
    const increasedValue = increaseByPercentage(fromPrice, formik.values.highestSellingPricePct);
    const customInputValue = new Big(increasedValue).round(currencyPairPriceScale, Big.roundDown).toNumber();

    return (
      <Stack spacing={1}>
        <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
          {t("exitStrategy.create.pogs.highestSellingPricePctTitle")}
        </Typography>
        <Typography variant="caption" fontWeight={400} pb={2}>
          {t("exitStrategy.create.pogs.highestSellingPricePctDescription")}
        </Typography>
        <InputWithLog
          id="highestSellingPricePct"
          precision={currencyPairPriceScale}
          label={t("exitStrategy.create.pogs.highestSellingPricePct")}
          formik={formik}
          currency={counterCurrency}
          logMin={10}
          logMax={1000}
          helperText={getApproxUsdPrice(customInputValue, counterCurrency)}
          disabledInput
          withIncrease={false}
          valueLabelDisplay="on"
          customInputValue={customInputValue}
          sliderColor="error.main"
        />
      </Stack>
    );
  };

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Stack spacing={1}>
          <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
            {t("exitStrategy.create.pogs.totalAmountTitle")}
          </Typography>
          <Typography variant="caption" fontWeight={400} pb={2}>
            {t("exitStrategy.create.pogs.totalAmountDescription")}
          </Typography>
          <InputWithSlider
            id="totalAmount"
            precision={baseCurrScale}
            label={t("exitStrategy.create.pogs.totalAmount")}
            formik={formik}
            currency={baseCurrency}
            max={details.totalAmount}
            sliderColor="primary.main"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack spacing={1}>
          <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
            {t("exitStrategy.create.pogs.firstSellingPriceTitle")}
          </Typography>
          <Typography variant="caption" fontWeight={400} pb={2}>
            {t("exitStrategy.create.pogs.firstSellingPriceDescription")}
          </Typography>
          <Stack
            sx={{ fontSize: "0.8em", color: theme.palette.grey[600], minHeight: "20px" }}
            pb={2}
            direction={"row"}
            flexWrap={"wrap"}
            columnGap={0.5}>
            <Typography fontSize={"inherit"}>{`${t("dcaBots.bidPrice")}:`}</Typography>
            <Stack direction={"row"} spacing={0.5}>
              <Typography fontWeight={600} fontSize={"inherit"}>
                {`${renderNumber(data?.bidPrice ?? 0, currencyPairPriceScale, true)}\u00a0${counterCurrency}`}
              </Typography>
              {getBidPrice(data?.bidPrice ?? 0, counterCurrency)}
            </Stack>
          </Stack>
          <InputWithLog
            id="firstSellingPrice"
            precision={currencyPairPriceScale}
            label={t("exitStrategy.create.pogs.firstSellingPrice")}
            formik={formik}
            currency={counterCurrency}
            baseValue={data.bidPrice}
            helperText={getApproxUsdPrice(formik.values.firstSellingPrice, counterCurrency)}
            sliderColor="error.main"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        {getHighestSellingPrice()}
      </Grid>
      <Grid item xs={12} lg={8}>
        <Chart
          scale={currencyPairPriceScale}
          counterCurrency={counterCurrency}
          exchangeEnum={exchangeEnum}
          currencyPair={currencyPair}
          data={previewData}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Stack spacing={1}>
          <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
            {t("exitStrategy.create.pogs.sellingPriceStepPctTitle")}
          </Typography>
          <Typography variant="caption" fontWeight={400} pb={1}>
            {t("exitStrategy.create.pogs.sellingPriceStepPctDescription")}
          </Typography>
          <StepInput id={"sellingPriceStepPct"} formik={formik} step={1} min={1} max={10} suffix={"%"} />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack spacing={1}>
          <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
            {t("exitStrategy.create.pogs.pctOfGrowthToSellTitle")}
          </Typography>
          <Typography variant="caption" fontWeight={400} pb={2}>
            {t("exitStrategy.create.pogs.pctOfGrowthToSellDescription")}
          </Typography>
          <InputWithLog
            id="pctOfGrowthToSell"
            precision={currencyPairPriceScale}
            label={t("exitStrategy.create.pogs.pctOfGrowthToSell")}
            formik={formik}
            currency={"%"}
            logMin={1}
            logMax={1000}
            disabledInput
            withIncrease={false}
            sliderColor="error.main"
          />
        </Stack>
      </Grid>
    </>
  );
};

export default PctOfGrowthSell;
