import { useEffect, useState } from "react";

import { Divider, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { deleteUser, logout } from "API/calls";
import PageTitle from "components/elements/PageTitle";
import Panel from "components/elements/Panel";
import { useUserDataContext } from "context/UserDataContext";
import useConfirm from "hooks/useConfirm";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import InvoiceSetup from "./InvoiceSetup";
import ModalChangePassword from "./ModalChangePassword";
import SettingsItem from "./SettingsItem";

const SettingsPage = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [modalChangePasswordOpen, setModalChangePasswordOpen] = useState(false);
  const [invoiceSetupOpen, setInvoiceSetupOpen] = useState(false);
  const { mfaEnabled, refreshData } = useUserDataContext();

  const onModalChangePasswordOpen = () => {
    setModalChangePasswordOpen(true);
  };

  const onModalChangePasswordClose = () => {
    setModalChangePasswordOpen(false);
  };

  const onInvoiceSetupOpen = () => setInvoiceSetupOpen(true);
  const onInvoiceSetupClose = () => setInvoiceSetupOpen(false);


  const handleDeteleAccount = async () => {
    try {
      await confirm({
        title: `${t("settingsPage.deleteAccount")}?`,
        description: t("settingsPage.deleteAccountConfirm"),
      });

      try {
        await deleteUser();
        await logout();
        window.location.replace("/");
      } catch (_error: any) {
        enqueueSnackbar(t("commonError"), { variant: "error" });
      }
    } catch (err: any) {
      // silent
    }
  };

  useEffect(() => {
    refreshData?.();
  }, [mfaEnabled]);

  return (
    <Grid container justifyContent="center" spacing={8}>
      <PageTitle i18nKey="settingsPage.meta_title" />
      <Grid item sx={{ width: "100%", maxWidth: "md" }}>
        <Panel sx={{ p: 4, mb: 10 }}>
          <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 3, color: "tertiary.main" }}>
            {t("settingsPage.title")}
          </Typography>
          <Stack spacing={2} divider={<Divider />}>
            <SettingsItem
              section={t("settingsPage.password")}
              value={t("settingsPage.passwordPlaceholder")}
              actionLabel={t("settingsPage.changePassword")}
              action={onModalChangePasswordOpen}
            />
            <SettingsItem
              section={t("settingsPage.mfa")}
              value={mfaEnabled ? t("on") : t("off")}
              description={t("settingsPage.mfaDescription")}
              actionLabel={mfaEnabled ? t("settingsPage.mfaDisable") : t("settingsPage.mfaSetup")}
              to={"/dvoufaktorove-overeni"}
            />
            <SettingsItem
              section={t("settingsPage.emailPreferences.title")}
              value={t("settingsPage.emailPreferences.description")}
              actionLabel={t("edit")}
              to={"/e-mailove-preference"}
            />
            <SettingsItem
              section={t("settingsPage.invoice")}
              value={t("settingsPage.invoiceDesc")}
              actionLabel={t("settingsPage.invoiceSetup")}
              action={onInvoiceSetupOpen}
            />
            <SettingsItem
              section={t("settingsPage.language")}
              value={t("language.cs")}
              actionLabel={t("settingsPage.comingSoon")}
            />
            <SettingsItem
              section={t("settingsPage.deleteAccount")}
              value={t("settingsPage.deleteAccountDesc")}
              actionLabel={t("delete")}
              action={handleDeteleAccount}
            />
          </Stack>
        </Panel>
        <ModalChangePassword open={modalChangePasswordOpen} onClose={onModalChangePasswordClose} />
        {invoiceSetupOpen && <InvoiceSetup onClose={onInvoiceSetupClose} />}
      </Grid>
    </Grid>
  );
};
export default SettingsPage;
