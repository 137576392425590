import { Box, InputAdornment } from "@mui/material";
import Big from "big.js";
import PriceField from "components/elements/PriceField";
import LogScale from "components/modules/DcaBotDetail/Balance/TradeModal/LimitBuy/LogScale";
import { increaseByPercentage, percentageChange } from "utils";
import { getBigWithComma } from "utils/formatter";

interface IProps {
  id: string;
  precision?: number;
  label: string;
  formik: any;
  currency: string;
  logMax?: number;
  logMin?: number;
  helperText?: string;
  withIncrease?: boolean;
  disabledInput?: boolean;
  valueLabelDisplay?: "off" | "auto" | "on";
  baseValue?: number;
  customInputValue?: number;
  sliderColor?: string;
}

const InputWithLog = ({
  id,
  precision,
  label,
  formik,
  currency,
  logMax = 100,
  logMin = 1,
  helperText,
  withIncrease = true,
  disabledInput,
  valueLabelDisplay = "auto",
  baseValue,
  customInputValue,
  sliderColor = "success.main",
}: IProps) => {
  const onSliderChange = (value: number) => {
    if (typeof value === "number") {
      const price = withIncrease && baseValue ? increaseByPercentage(baseValue, value) : value;
      formik.setFieldValue(id, getBigWithComma(new Big(price).round(precision, Big.roundDown)));
    }
  };

  const getSliderDefaultValue = (origin: number, current: number | string) => {
    const result = percentageChange(origin, Number(`${current}`.replace(",", ".")));
    if (result < logMin) return logMin;
    if (result > logMax) return logMax;
    return result;
  };

  return (
    <>
      <PriceField
        autoComplete="off"
        allowZero
        precision={precision}
        fullWidth={true}
        id={id}
        name={id}
        label={label}
        value={customInputValue ?? formik.values[id]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors[id])}
        helperText={formik.errors[id] || helperText}
        InputProps={{
          endAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
        }}
        disabled={disabledInput}
      />
      <Box sx={{ px: 1, pt: { xs: 1.75, md: 2.75 }}}>
        <LogScale
          key={`sliderLog-${id}`}
          value={formik.values[id]}
          defaultValue={baseValue ? getSliderDefaultValue(baseValue, formik.values[id]) : formik.values[id]}
          min={logMin}
          step={1}
          max={logMax}
          valueLabelFormat={(n: number) => `+${n} %`}
          onChange={onSliderChange}
          valueLabelDisplay={valueLabelDisplay}
          sx={{ color: sliderColor }}
        />
      </Box>
    </>
  );
};

export default InputWithLog;
