import { subscriptionDcaBots, subscriptionCost, subscriptionLimitOrders, subscriptionSingleLimitOrders } from "constants/subscriptions";

import React, { useEffect, useState } from "react";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Box,
  useMediaQuery,
  Link,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { subscription } from "API/calls";
import Panel from "components/elements/Panel";
import { Credit } from "components/icons/components/Credit";
import { useUserDataContext } from "context/UserDataContext";
import useConfirm from "hooks/useConfirm";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { SubscriptionType } from "types/enums";
import { ISubscriptionResponse } from "types/types";
import { ConditionalWrapper, getColorByMode } from "utils";

import ModalDowngradingFail from "./ModalDowngradingFail";
import { IDowngradingFailData } from "./ModalDowngradingFail/types";
import ModalNotEnoughCredits from "./ModalNotEnoughCredits";

interface TableContent {
  id: SubscriptionType;
  color: string;
  currencyPairs: string;
  dcaFrequency: string;
  targetValue: string;
  fillLimitOrders: string;
  btcWithdrawal: string;
  maxActiveBots: string;
  gridStrategyMinPercentStep: string;
  dashboardStrategiesDetails: string;
  dashboardStrategiesSummary: string;
  cashbackSatsAvailable: string;
  dynamicDcaOrderValue: string;
  exitStrategy: string;
  manualOrders: string;
}

const tiers: TableContent[] = [
  {
    id: SubscriptionType.FREE,
    color: "gray",
    currencyPairs: "currencyPairsFree",
    dcaFrequency: "dcaFrequencyFree",
    targetValue: "blankPlaceholder",
    fillLimitOrders: "fillLimitOrdersFree",
    btcWithdrawal: "blankPlaceholder",
    maxActiveBots: "1",
    gridStrategyMinPercentStep: "2,5",
    dashboardStrategiesDetails: "blankPlaceholder",
    dashboardStrategiesSummary: "blankPlaceholder",
    cashbackSatsAvailable: "blankPlaceholder",
    dynamicDcaOrderValue: "blankPlaceholder",
    exitStrategy: "exitStrategyFree",
    manualOrders: "blankPlaceholder",
  },
  {
    id: SubscriptionType.PLUS,
    color: "tertiary.main",
    currencyPairs: "currencyPairsPlus",
    dcaFrequency: "dcaFrequencyPlus",
    targetValue: "yes",
    fillLimitOrders: "fillLimitOrdersPlus",
    btcWithdrawal: "yes",
    maxActiveBots: "2",
    gridStrategyMinPercentStep: "2",
    dashboardStrategiesDetails: "yes",
    dashboardStrategiesSummary: "blankPlaceholder",
    cashbackSatsAvailable: "blankPlaceholder",
    dynamicDcaOrderValue: "blankPlaceholder",
    exitStrategy: "exitStrategyPlus",
    manualOrders: "blankPlaceholder",
  },
  {
    id: SubscriptionType.PREMIUM,
    color: "primary",
    currencyPairs: "currencyPairsPremium",
    dcaFrequency: "dcaFrequencyPremium",
    targetValue: "yes",
    fillLimitOrders: "fillLimitOrdersPremium",
    btcWithdrawal: "yes",
    maxActiveBots: "4",
    gridStrategyMinPercentStep: "1",
    dashboardStrategiesDetails: "yes",
    dashboardStrategiesSummary: "yes",
    cashbackSatsAvailable: "yes",
    dynamicDcaOrderValue: "yes",
    exitStrategy: "exitStrategyPremium",
    manualOrders: "yes",
  },
];

interface Props {
  cta?: boolean;
  backgroundOnMobile?: boolean;
}

const SubscriptionsPage: React.FC<Props> = ({ cta = true, backgroundOnMobile, children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { subscriptionType: actualSubscription, refreshData, creditsRechargeType } = useUserDataContext();
  const [modalDowngradingFailOpen, setModalDowngradingFailOpen] = useState<boolean>(false);
  const [modalNotEnoughCreditsOpen, setModalNotEnoughCreditsOpen] = useState<boolean>(false);
  const [subscriptionResponse, setSubscriptionResponse] = useState<IDowngradingFailData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const currencyPairsListLink = "/menove-pary";
  const dcaStrategyTutorialLink = "/proc-bitcoin/stosuj-cz-navod-krok-za-krokem";
  const cashbackSatsTutorialLink = "/proc-bitcoin/cashback-saty";
  const dynamicDcaStrategyTutorialLink = "/proc-bitcoin/dynamicka-dca-strategie";
  const gridStrategyTutorialLink = "/proc-bitcoin/predstaveni-grid-strategie";

  useEffect(() => {
    if(isLoading) setIsLoading(false);
  }, [actualSubscription])

  function renderRow(name: React.ReactNode, row: any[]) {
    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left">{name}</TableCell>
        {row.map((value, index) => (
          <TableCell key={index} align="center">
            {value}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  const showCreditsRechargeTypeActiveModal = async () => {
    try {
      await confirm({
        title: t("subscriptions.creditsRechargeTypeActiveModal.title"),
        description: t("subscriptions.creditsRechargeTypeActiveModal.description"),
        dialogProps: { maxWidth: "md" },
        confirmationText: t("subscriptions.creditsRechargeTypeActiveModal.button"),
        cancellationButtonProps: { sx: { display: "none" } },
      });
    } catch (err: any) {
      // silent
    }
  };

  function changeSubscription(subscriptionType: SubscriptionType) {
    setSubscriptionResponse(undefined);
    setIsLoading(true);

    subscription(subscriptionType)
      .then((response) => {
        const subscriptionResponse: ISubscriptionResponse = response?.data;
        if (subscriptionResponse.ok) {
          enqueueSnackbar(t("activated"), { variant: "success" });
          refreshData && refreshData();
          if (creditsRechargeType && subscriptionType !== SubscriptionType.PREMIUM) {
            showCreditsRechargeTypeActiveModal();
          }
        } else {
          setModalDowngradingFailOpen(true);
          setSubscriptionResponse({ ...subscriptionResponse, subscriptionType });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.httpStatus === "FORBIDDEN") {
          setModalNotEnoughCreditsOpen(true);
        } else {
          enqueueSnackbar(t("notActivated"), { variant: "error" });
        }
      });
  }

  function renderSubscriptionButton(subscriptionType: SubscriptionType) {
    if (subscriptionType === actualSubscription) {
      return <Typography color="success.light">{t("activeAlready")}</Typography>;
    }
    return (
      <Button
        color="success"
        variant="contained"
        disabled={isLoading}
        onClick={() => changeSubscription(subscriptionType)}>
        {t("activate")} {t(subscriptionType)}
      </Button>
    );
  }

  const onModalDowngradingFailClose = () => {
    setModalDowngradingFailOpen(false);
    if(isLoading) setIsLoading(false);
  }

  const onModalNotEnoughCredits = () => {
    setModalNotEnoughCreditsOpen(false);
    if(isLoading) setIsLoading(false);
  }

  return (
    <>
      {isMobile && (
        <>
          {children && <Panel sx={{ mb: 4, p: 3, pb: 2 }}>{children}</Panel>}
          {tiers.map((tier, index) => (
            <ConditionalWrapper
              key={index}
              condition={!!backgroundOnMobile}
              wrapper={(children) => <Panel sx={{ pt: 1, pb: 3, px: 3, mb: 2 }}>{children}</Panel>}>
              <Box sx={{ mt: 4 }}>
                <Typography color={tier.color} variant="h4" sx={{ fontWeight: 600, textAlign: "center" }}>
                  {t(tier.id)}
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody
                      sx={{
                        "tr td": {
                          fontSize: { sm: "1em", xs: "0.8em" },
                          fontWeight: 400,
                          color: getColorByMode(theme, theme.palette.grey[600], theme.palette.grey[300]),
                        },
                        "& > tr td:first-of-type": { fontWeight: 600, color: getColorByMode(theme, theme.palette.grey[700], theme.palette.grey[300]) },
                        "& > tr:last-child td": { border: 0 },
                      }}>
                      <TableRow>
                        <TableCell sx={{ pl: 0 }}>{t("priceForOneMonth")}</TableCell>
                        <TableCell sx={{ pr: 0, overflow: "hidden" }} align="right">
                          {index === 0 && t("foreverFree")}
                          {index > 0 && (
                            <Box
                              sx={{
                                fontWeight: 400,
                                display: "inline-flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}>
                              <strong>{subscriptionCost[tier.id]}</strong>
                              <Credit sx={{ marginLeft: "4px" }} />
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{pl: 0}}>
                          {t("dcaStrategiesCount")}
                          <br/>
                          <small>{t("dcaStrategiesCountLabel")} ‑&nbsp;<Link to={dcaStrategyTutorialLink} component={RouterLink}>
                            <Trans i18nKey="showTutorialLink"/>
                          </Link>
                          </small>
                        </TableCell>
                        <TableCell sx={{pr: 0}} align="right">
                          {subscriptionDcaBots[tier.id]}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{pl: 0}}>
                        {t("cashbackSatsAvailable")}&nbsp;🧡
                          <br />
                          <small>{t("cashbackSatsAvailableLabel")} ‑&nbsp;<Link to={cashbackSatsTutorialLink} component={RouterLink}>
                            <Trans i18nKey="cashbackSatsConditionsLink"/>
                          </Link>
                          </small>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          {tier.cashbackSatsAvailable === "yes" ? (
                            <strong>
                              {t(tier.cashbackSatsAvailable)}&nbsp;✅
                            </strong>
                          ) : (
                            <>
                            {t(tier.cashbackSatsAvailable)}
                          </>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{pl: 0}}>
                          {t("dynamicDcaOrderValue")}&nbsp;💎
                          <br />
                          <small>{t("dynamicDcaOrderValueLabel")} ‑&nbsp;<Link to={dynamicDcaStrategyTutorialLink} component={RouterLink}>
                            <Trans i18nKey="showTutorialLink"/>
                          </Link>
                          </small>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          {tier.dynamicDcaOrderValue === "yes" ? (
                            <strong>
                              {t(tier.dynamicDcaOrderValue)}&nbsp;✅
                            </strong>
                          ) : (
                            <>
                              {t(tier.dynamicDcaOrderValue)}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{pl: 0}}>
                          {t("manualOrders")}
                          <br/>
                          <small>{t("manualOrdersLabel")}</small>
                        </TableCell>
                        <TableCell sx={{pr: 0}} align="right">
                          {tier.cashbackSatsAvailable === "yes" ? (
                            <strong>
                              {t(tier.manualOrders)}&nbsp;✅
                            </strong>
                          ) : (
                            <>
                              {t(tier.manualOrders)}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{pl: 0}}>
                          {t("maxActiveBots")}
                          <br/>
                          <small>
                            {t("maxActiveBotsLabel")} -&nbsp;<Link to={gridStrategyTutorialLink} component={RouterLink}>
                            <Trans i18nKey="showTutorialLink"/>
                          </Link>
                          </small>
                        </TableCell>
                        <TableCell sx={{pr: 0}} align="right">
                          {t(tier.maxActiveBots)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ pl: 0 }}>
                          {t("gridStrategyMinPercentStep")}
                          <br />
                          <small>{t("gridStrategyMinPercentStepLabel")}</small>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          <strong>{t(tier.gridStrategyMinPercentStep)} %</strong>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ pl: 0 }}>
                          {t("currencyPairs")}
                          <br />
                          <small>
                            <Link to={currencyPairsListLink} component={RouterLink}>
                              <Trans i18nKey="currencyPairsList" />
                            </Link>
                          </small>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          <Trans
                            i18nKey={tier.currencyPairs}
                            components={{ a: <Link to={currencyPairsListLink} component={RouterLink} /> }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ pl: 0 }}>{t("dcaFrequency")}</TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          {t(tier.dcaFrequency)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ pl: 0 }}>
                          {t("fillLimitOrdersFeature")}
                          <br />
                          <small>{t("forDcaBot")}</small>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          {t(tier.fillLimitOrders)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ pl: 0 }}>
                          {t("limitOrdersCount")}
                          <br />
                          <small>{t("inASingleOrder")}</small>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          {subscriptionLimitOrders[tier.id]}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ pl: 0 }}>
                          {t("singleLimitOrdersCount")}
                          <br />
                          <small>{t("inDcaBot")}</small>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          {subscriptionSingleLimitOrders[tier.id]}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ pl: 0 }}>
                          {t("targetValueFeature")}
                          <br />
                          <small>{t("forDcaBot")}</small>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          {t(tier.targetValue)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ pl: 0 }}>
                          {t("btcWithdrawal")}
                          <br />
                          <small>{t("toAddress")}</small>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          {t(tier.btcWithdrawal)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ pl: 0 }}>
                          {t("dashboardStrategiesDetails")}
                          <br />
                          <small>{t("dashboardStrategiesDetailsOnDashboard")}</small>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          {t(tier.dashboardStrategiesDetails)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ pl: 0 }}>
                          {t("dashboardStrategiesSummary")}
                          <br />
                          <small>{t("dashboardStrategiesSummaryOnDashboard")}</small>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          {t(tier.dashboardStrategiesSummary)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ pl: 0 }}>
                          {t("nextAutomaticStrategies")}
                          <br />
                          <small>({t("comingSoon")})</small>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }} align="right">
                          {t(tier.exitStrategy)}
                        </TableCell>
                      </TableRow>
                      {cta && (
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            {renderSubscriptionButton(tier.id)}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </ConditionalWrapper>
          ))}
        </>
      )}

      {!isMobile && (
        <Panel sx={{ mb: 4, p: 3 }}>
          {children}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="right" sx={{ width: "40%" }} />
                  <TableCell align="center" sx={{ width: "20%" }}>
                    <Typography color="gray" variant="h6" sx={{ fontWeight: 600 }}>
                      {t(SubscriptionType.FREE)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ width: "20%" }}>
                    <Typography color="tertiary.main" variant="h6" sx={{ fontWeight: 600 }}>
                      {t(SubscriptionType.PLUS)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ width: "20%" }}>
                    <Typography color="primary" variant="h6" sx={{ fontWeight: 600 }}>
                      {t(SubscriptionType.PREMIUM)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "tr td": { fontSize: "1em", fontWeight: 400, color: getColorByMode(theme, theme.palette.grey[700], theme.palette.grey[300]) },
                  "& > tr td:first-of-type": { fontWeight: 600, color: getColorByMode(theme, theme.palette.grey[700], theme.palette.grey[300]) },
                  "& > tr:last-child td": { border: 0 },
                }}>
                <TableRow>
                  <TableCell rowSpan={2}>{t("priceForOneMonth")}</TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t("foreverFree")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  {[subscriptionCost[tiers[1].id], subscriptionCost[tiers[2].id]].map((value, index) => (
                    <TableCell key={index} align="center">
                      <Box sx={{ fontWeight: 400, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <strong>{value}</strong>
                        <Credit sx={{ marginLeft: "4px" }} />
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>

                {renderRow(
                  <>
                    {t("dcaStrategiesCount")}
                    <br/>
                    <small>{t("dcaStrategiesCountLabel")} -&nbsp;<Link to={dcaStrategyTutorialLink} component={RouterLink}>
                      <Trans i18nKey="showTutorialLink"/>
                    </Link>
                    </small>
                  </>,
                  tiers.map((tier) => subscriptionDcaBots[tier.id])
                )}
                {renderRow(
                  <>
                    {t("cashbackSatsAvailable")}&nbsp;🧡
                    <br/>
                    <small>
                      {t("cashbackSatsAvailableLabel")} -&nbsp;<Link to={cashbackSatsTutorialLink} component={RouterLink}>
                      <Trans i18nKey="cashbackSatsConditionsLink"/>
                    </Link>
                    </small>
                  </>,
                  tiers.map((tier) => tier.cashbackSatsAvailable === "yes" ? (
                      <strong>
                        {t(tier.cashbackSatsAvailable)}&nbsp;✅
                      </strong>
                    ) : (
                      <>
                        {t(tier.cashbackSatsAvailable)}
                      </>
                    )
                  ))}
                {renderRow(
                  <>
                    {t("dynamicDcaOrderValue")}&nbsp;💎
                    <br/>
                    <small>
                      {t("dynamicDcaOrderValueLabel")} -&nbsp;<Link to={dynamicDcaStrategyTutorialLink} component={RouterLink}>
                        <Trans i18nKey="showTutorialLink"/>
                      </Link>
                    </small>
                  </>,
                  tiers.map((tier) => tier.dynamicDcaOrderValue === "yes" ? (
                      <strong>
                        {t(tier.dynamicDcaOrderValue)}&nbsp;✅
                      </strong>
                    ) : (
                      <>
                        {t(tier.dynamicDcaOrderValue)}
                    </>
                    )
                  ))}
                {renderRow(
                  <>
                    {t("manualOrders")}
                    <br/>
                    <small>{t("manualOrdersLabel")}</small>
                  </>,
                  tiers.map((tier) => tier.manualOrders === "yes" ? (
                    <strong>
                      {t(tier.manualOrders)}&nbsp;✅
                    </strong>
                  ) : (
                    <>
                      {t(tier.manualOrders)}
                    </>
                  )
                  ))}
                {renderRow(
                  <>
                    {t("maxActiveBots")}
                    <br/>
                    <small>
                      {t("maxActiveBotsLabel")} -&nbsp;<Link to={gridStrategyTutorialLink} component={RouterLink}>
                      <Trans i18nKey="showTutorialLink"/>
                    </Link>
                    </small>
                  </>,
                  tiers.map((tier) => t(tier.maxActiveBots))
                )}
                {renderRow(
                  <>
                    {t("gridStrategyMinPercentStep")}
                    <br />
                    <small>{t("gridStrategyMinPercentStepLabel")}</small>
                  </>,
                  tiers.map((tier) => (
                    <strong>
                      {t(tier.gridStrategyMinPercentStep)} {"%"}
                    </strong>
                  ))
                )}
                {renderRow(
                  <>
                    {t("currencyPairs")}
                    <br />
                    <small>
                      <Link to={currencyPairsListLink} component={RouterLink}>
                        <Trans i18nKey="currencyPairsList" />
                      </Link>
                    </small>
                  </>,
                  tiers.map((tier) => (
                    <Trans
                      i18nKey={tier.currencyPairs}
                      components={{ a: <Link to={currencyPairsListLink} component={RouterLink} /> }}
                    />
                  ))
                )}
                {renderRow(
                  t("dcaFrequency"),
                  tiers.map((tier) => t(tier.dcaFrequency))
                )}
                {renderRow(
                  <>
                    {t("fillLimitOrdersFeature")}
                    <br />
                    <small>{t("forDcaBot")}</small>
                  </>,
                  tiers.map((tier) => t(tier.fillLimitOrders))
                )}
                {renderRow(
                  <>
                    {t("limitOrdersCount")}
                    <br />
                    <small>{t("inASingleOrder")}</small>
                  </>,
                  tiers.map((tier) => subscriptionLimitOrders[tier.id])
                )}
                {renderRow(
                  <>
                    {t("singleLimitOrdersCount")}
                    <br />
                    <small>{t("inDcaBot")}</small>
                  </>,
                  tiers.map((tier) => subscriptionSingleLimitOrders[tier.id])
                )}
                {renderRow(
                  <>
                    {t("targetValueFeature")}
                    <br />
                    <small>{t("forDcaBot")}</small>
                  </>,
                  tiers.map((tier) => t(tier.targetValue))
                )}
                {renderRow(
                  <>
                    {t("btcWithdrawal")}
                    <br />
                    <small>{t("toAddress")}</small>
                  </>,
                  tiers.map((tier) => t(tier.btcWithdrawal))
                )}
                {renderRow(
                  <>
                    {t("dashboardStrategiesDetails")}
                    <br />
                    <small>{t("dashboardStrategiesDetailsOnDashboard")}</small>
                  </>,
                  tiers.map((tier) => t(tier.dashboardStrategiesDetails))
                )}
                {renderRow(
                  <>
                    {t("dashboardStrategiesSummary")}
                    <br />
                    <small>{t("dashboardStrategiesSummaryOnDashboard")}</small>
                  </>,
                  tiers.map((tier) => t(tier.dashboardStrategiesSummary))
                )}
                {renderRow(
                  <>
                    {t("nextAutomaticStrategies")}
                    <br />
                    <small>({t("comingSoon")})</small>
                  </>,
                  tiers.map((tier) => t(tier.exitStrategy))
                )}
                {cta && (
                  <TableRow>
                    <TableCell />
                    <TableCell align="center">{renderSubscriptionButton(SubscriptionType.FREE)}</TableCell>
                    <TableCell align="center">{renderSubscriptionButton(SubscriptionType.PLUS)}</TableCell>
                    <TableCell align="center">{renderSubscriptionButton(SubscriptionType.PREMIUM)}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Panel>
      )}
      {modalDowngradingFailOpen && (
        <ModalDowngradingFail
          open={modalDowngradingFailOpen}
          onClose={onModalDowngradingFailClose}
          data={subscriptionResponse}
        />
      )}
      {modalNotEnoughCreditsOpen && (
        <ModalNotEnoughCredits open={modalNotEnoughCreditsOpen} onClose={onModalNotEnoughCredits} />
      )}
    </>
  );
};

export default SubscriptionsPage;
