import validations from "constants/validations";

import { useEffect } from "react";

import { MenuItem, Stack, TextField, FormControlLabel, Checkbox } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import BackdropLoading from "components/elements/BackdropLoading";
import Modal from "components/elements/Modal";
import Select from "components/elements/Select";
import { useFormik } from "formik";
import { useAPICountryCodes, useAPIUserInvoiceData } from "hooks/useAPI";
import { useTranslation } from "react-i18next";
import { IInvoiceData } from "types/types";
import * as yup from "yup";

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: (values: IInvoiceData) => void;
}

const ModalInvoiceSetup = ({ open, onClose, onSuccess }: Props) => {
  const { t } = useTranslation();
  const { data: userCountryCodes, isValidating: userCountryCodesValidating } = useAPICountryCodes();
  const { data: userInvoiceData, isValidating: userInvoiceDataValidating } = useAPIUserInvoiceData();
  const initValues = {
    firstName: userInvoiceData?.invoiceData?.firstName ?? "",
    lastName: userInvoiceData?.invoiceData?.lastName ?? "",
    countryCode: userInvoiceData?.invoiceData?.countryCode ?? "",
    streetAndNumber: userInvoiceData?.invoiceData?.streetAndNumber ?? "",
    city: userInvoiceData?.invoiceData?.city ?? "",
    postalCode: userInvoiceData?.invoiceData?.postalCode ?? "",
    addBusinessData: !!userInvoiceData?.invoiceData?.businessData,
    businessData: {
      businessName: userInvoiceData?.invoiceData?.businessData?.businessName ?? "",
      businessIdentificationNumber: userInvoiceData?.invoiceData?.businessData?.businessIdentificationNumber ?? "",
      businessTaxNumber: userInvoiceData?.invoiceData?.businessData?.businessTaxNumber ?? "",
    },
  };

  const regexValidation = (field: string, requiredIfRegex = false) => (val?: string, context?: any) => {
      const { from, schema } = context;

      if (Array.isArray(from)) {
        // find selectedCountryCode in formik values
        let selectedCountryCode = "";
        from.forEach((element) => {
          if (!selectedCountryCode) selectedCountryCode = element?.value?.countryCode;
        });

        // get contryCodeData from countryCodes by selectedCountryCode
        const contryCodeData = userCountryCodes?.countryCodes.find((el) => el.countryCode === selectedCountryCode);
        if (contryCodeData) {
          const regex = contryCodeData[field as keyof typeof contryCodeData] as string;
          // do not validate if regex is null
          if (!regex) return true;

          if (!val) {
            // if value is empty and "requiredIfRegexExist" is true, mark input as required, otherwise do not validate
            if (schema?.exclusiveTests?.required || requiredIfRegex) {
              return context.createError({
                message: t("fieldRequired"),
              });
            } else if (field.includes("businessTaxNumber") && selectedCountryCode !== "CZ" && contryCodeData.inEU) {
              return context.createError({
                message: t("fieldRequired"),
              });
            } else return true;
          }

          //regex validation
          return new RegExp(`^${regex}$`).test(val);
        }

        // do not validate if countryCodeData does not exist
        return true;
      }

      // do not validate if formik context does not exist
      return true;
    };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({
      firstName: yup.string().trim().max(100, t("fieldMaxLength", { max: 100 })).required(t("fieldRequired")),
      lastName: yup.string().trim().max(100, t("fieldMaxLength", { max: 100 })).required(t("fieldRequired")),
      countryCode: yup.string().required(t("fieldRequired")),
      streetAndNumber: yup.string().trim().max(100, t("fieldMaxLength", { max: 100 })).required(t("fieldRequired")),
      city: yup.string().trim().max(100, t("fieldMaxLength", { max: 100 })).required(t("fieldRequired")),
      postalCode: yup.string().required(t("fieldRequired")).matches(validations.postalCode, t("fieldErrors.postalCode")),
      addBusinessData: yup.bool().required(t("fieldRequired")),
      businessData: yup.object().when("addBusinessData", {
        is: true,
        then: yup.object().shape({
          businessName: yup.string().max(100, t("fieldMaxLength", { max: 100 })),
          businessIdentificationNumber: yup
            .string()
            .max(20, t("fieldMaxLength", { max: 20 }))
            .test(
              "businessIdentificationNumberTest",
              t("fieldErrors.businessIdentificationNumber"),
              regexValidation("businessIdentificationNumberRegex", true)
            ),
          businessTaxNumber: yup
            .string()
            .max(20, t("fieldMaxLength", { max: 20 }))
            .test(
              "businessTaxNumberTest",
              t("fieldErrors.businessTaxNumber"),
              regexValidation("businessTaxNumberRegex")
            ),
        }),
      }),
    }),
    onSubmit: async ({ businessData, addBusinessData, ...rest }) => {
      onSuccess({
        ...rest,
        businessData: addBusinessData ? businessData : null,
      });
    },
  });

  useEffect(() => {
    if (userCountryCodes && userCountryCodes?.countryCodes[0]?.countryCode && !formik.values.countryCode.length) {
      formik.setFieldValue("countryCode", userCountryCodes?.countryCodes[0]?.countryCode);
    }
  }, [userCountryCodes]);

  const getBusinessTaxNumberLabel = () => {
    switch (formik.values.countryCode) {
      case "CZ":
        return "DIČ";
      case "SK":
        return "IČ DPH";
      default:
        return "VAT ID";
    }
  };

  const handleCancel = () => {
    formik.handleReset(null);
    onClose();
  }

  return (
    <Modal
      open={open}
      confirmTitle={t("settingsPage.invoiceSetup")}
      onConfirm={() => formik.handleSubmit()}
      onCancel={handleCancel}
      withCloseButton
      disableCloseOnClickOutside
      title={t("settingsPage.invoiceSetupModal.title")}>
      <DialogContentText sx={{ mb: 2, fontSize: "0.85rem" }}>
        {t("settingsPage.invoiceSetupModal.content")}
      </DialogContentText>
      <Stack spacing={2}>
        <TextField
          fullWidth={true}
          id={"firstName"}
          name={"firstName"}
          label={t("settingsPage.invoiceSetupModal.firstName")}
          value={formik.values.firstName}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          onChange={formik.handleChange}
          autoComplete={"off"}
        />
        <TextField
          fullWidth={true}
          id={"lastName"}
          name={"lastName"}
          label={t("settingsPage.invoiceSetupModal.lastName")}
          value={formik.values.lastName}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          onChange={formik.handleChange}
          autoComplete={"off"}
        />
        <Select
          id="countryCode"
          name="countryCode"
          label={t("settingsPage.invoiceSetupModal.countryCode")}
          value={formik.values.countryCode ?? ""}
          onChange={formik.handleChange}
          error={formik.touched.countryCode && formik.errors.countryCode}>
          {userCountryCodes?.countryCodes?.map((code, index) => (
            <MenuItem key={`countryCode-${index}`} value={code.countryCode}>
              {code.countryName["CS"]}
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth={true}
          id={"streetAndNumber"}
          name={"streetAndNumber"}
          label={t("settingsPage.invoiceSetupModal.streetAndNumber")}
          value={formik.values.streetAndNumber}
          error={formik.touched.streetAndNumber && Boolean(formik.errors.streetAndNumber)}
          helperText={formik.touched.streetAndNumber && formik.errors.streetAndNumber}
          onChange={formik.handleChange}
          autoComplete={"off"}
        />
        <TextField
          fullWidth={true}
          id={"city"}
          name={"city"}
          label={t("settingsPage.invoiceSetupModal.city")}
          value={formik.values.city}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
          onChange={formik.handleChange}
          autoComplete={"off"}
        />
        <TextField
          fullWidth={true}
          id={"postalCode"}
          name={"postalCode"}
          label={t("settingsPage.invoiceSetupModal.postalCode")}
          value={formik.values.postalCode}
          error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
          helperText={formik.touched.postalCode && formik.errors.postalCode}
          onChange={formik.handleChange}
          autoComplete={"off"}
        />
        <FormControlLabel
          control={<Checkbox />}
          id={"addBusinessData"}
          name={"addBusinessData"}
          label={`${t("settingsPage.invoiceSetupModal.addBusinessData")}`}
          checked={formik.values.addBusinessData}
          onChange={formik.handleChange}
        />
        {formik.values.addBusinessData && (
          <>
            <TextField
              fullWidth={true}
              id={"businessData.businessName"}
              name={"businessData.businessName"}
              label={t("settingsPage.invoiceSetupModal.businessName")}
              value={formik.values.businessData.businessName}
              error={formik.touched.businessData?.businessName && Boolean(formik.errors.businessData?.businessName)}
              helperText={formik.touched.businessData?.businessName && formik.errors.businessData?.businessName}
              onChange={formik.handleChange}
              autoComplete={"off"}
            />
            <TextField
              fullWidth={true}
              id={"businessData.businessIdentificationNumber"}
              name={"businessData.businessIdentificationNumber"}
              label={t("settingsPage.invoiceSetupModal.businessIdentificationNumber")}
              value={formik.values.businessData.businessIdentificationNumber}
              error={
                formik.touched.businessData?.businessIdentificationNumber
                && Boolean(formik.errors.businessData?.businessIdentificationNumber)
              }
              helperText={
                formik.touched.businessData?.businessIdentificationNumber
                && formik.errors.businessData?.businessIdentificationNumber
              }
              onChange={formik.handleChange}
              autoComplete={"off"}
            />
            <TextField
              fullWidth={true}
              id={"businessData.businessTaxNumber"}
              name={"businessData.businessTaxNumber"}
              label={getBusinessTaxNumberLabel()}
              value={formik.values.businessData.businessTaxNumber}
              error={
                formik.touched.businessData?.businessTaxNumber && Boolean(formik.errors.businessData?.businessTaxNumber)
              }
              helperText={
                formik.touched.businessData?.businessTaxNumber && formik.errors.businessData?.businessTaxNumber
              }
              onChange={formik.handleChange}
              autoComplete={"off"}
            />
          </>
        )}
      </Stack>
      <BackdropLoading open={userCountryCodesValidating || userInvoiceDataValidating} />
    </Modal>
  );
};

export default ModalInvoiceSetup;
